<template>
  <div>
    <p class="mb-4 text-xl text-left text-gray-500">Questionnaire de sécurité</p>

    <flow-form ref="flowform" v-on:complete="onComplete" v-bind:questions="questions"
      v-bind:language="language" v-bind:standalone="true">
    </flow-form>
  </div>

</template>

<script>
// Import necessary components and classes
import FlowForm from '../components/FlowForm.vue'
import QuestionModel, { QuestionType, ChoiceOption, LinkOption, MatrixColumn, MatrixRow } from '../models/QuestionModel'
import LanguageModel from '../models/LanguageModel'
import questionsJSON from '../assets/questions/questions.json'

const baseUrl = process.env.VUE_APP_API_BASE_URL;


export default {
  name: 'formtest',
  components: {
    FlowForm,
  },
  data() {
    return {
      language: new LanguageModel(),
      categories: ['Sensibilité & Hébergement', 'Perte de fichiers & Sauvegardes', 'Poste de travail & Sécurité', 'Autres'],
      questions: this.getQuestions()
    }
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyListener)
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyListener)
  },
  methods: {
    onKeyListener($event) {
      // We've overriden the default "complete" slot so
      // we need to implement the "keyup" listener manually.

      if ($event.key === 'Enter' && this.completed && !this.submitted) {
        this.onComplete()
      }
    },
    async onComplete(completed, questionList) {
      var navigate = this.$router;
      const dataDict = this.getQuestionsReponses('normal')
      const rawResponse = this.getQuestionsReponses('raw')
      let ip = ' ';
      const date = new Date()
      let email = '';
      let sector = '';
      let employee = '';

      this.questions.forEach(question => {
        if(question.id == 'email') {
          email = question.answer;
        }

        if(question.id == 'XA') {
          sector = question.answer;
        }

        if(question.id == 'XB') {
          employee = question.answer;
        }
      })

      let fullData = [dataDict, [ip, date, rawResponse, email, sector, employee]]
      fullData = JSON.stringify(fullData)

      fetch(`${baseUrl}/sondage/`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: fullData })
        .then(function(response) {
          return response.text();
        })
        .then(function(data) {
          localStorage.setItem('responseRaw', data)
          localStorage.setItem('reponsesSlash', JSON.stringify(dataDict))

          if(email) {
            localStorage.setItem('sendEmail', "true")
            localStorage.setItem('email', email)
          } else {
            localStorage.setItem('sendEmail', "false")
          }

          setTimeout(() => {
            navigate.push('answer');
          }, 2000);
        });
    },
    getQuestionsReponses(type) {
      let i = 1
      let category1 = []
      let category2 = []
      let category3 = []
      let otherQuestions = []
      let allCategories = []
      let isInternal = false;

      this.questions.forEach(question => {
        let toAdd = "";
        let answer = question.answer;

        if (Array.isArray(answer)) {
          answer = answer.join(', ');
        }

        if(i != this.questions.length) {
          toAdd = '/';
        }

        if(type == 'normal') {
          switch (question.category) {
            case 'category1':
              category1 += question.id + question.answer + toAdd;
              break;
            case 'category2':
              if(question.answer) {
                category2 += question.id + question.answer + toAdd;
              } else {
                category2 += question.id + "6" + toAdd;
              }
              break;
            case 'category3':
              category3 += question.id + question.answer + toAdd;
              break;
            case 'xxx':
              otherQuestions += question.id + question.answer + toAdd;
              break;
          }
          
          i = i + 1;
        } 
        else if (type == 'raw') {
          if(question.id != 'email' && question.id != 'XXX') {
            if(question.answer) {
              allCategories += question.id + question.answer + toAdd;
            } else {
              allCategories += question.id + "6" + toAdd;
            }
          }
        }
      })

      if(type == 'normal') {
        allCategories.push(category1);
        allCategories.push(category2);
        allCategories.push(category3);
        allCategories.push(otherQuestions);
      }

      return allCategories;
    },
    getQuestions() {
      let allQuesions = []

      for (let i = 0; i < questionsJSON.length; i++) {
        const questionJSON = questionsJSON[i];
        let type = ""

        if(questionJSON.type == 'dropdown') {
          type = QuestionType.Dropdown;
        } else if(questionJSON.type == 'multiple-choice') {
          type = QuestionType.MultipleChoice;
        } else if(questionJSON.type == 'email') {
          type = QuestionType.Email;
        }

        let question = new QuestionModel({
            id: questionJSON.id,
            title: questionJSON.title,
            type: type,
            multiple: questionJSON.multiple,
            required: questionJSON.required,
        });

        // Get options
        if(questionJSON.options) {
          let options = []
 
          questionJSON.options.forEach(option => {
            let choiceOption = new ChoiceOption({
              value: option.value,
              label: option.label,
            })

            options.push(choiceOption)
          });

          question['options'] = options;
        }

        // Get redirect
        if(questionJSON.jump) {
          let jump = {}

          questionJSON.jump.forEach(element => {
            jump[element.id] = element.to;  
          });

          question['jump'] = jump;
        }


        if(questionJSON.helpText) {
          question['helpText'] = questionJSON.helpText;
        }

        if(questionJSON.category) {
          question['category'] = questionJSON.category;
          question['categoryText'] = questionJSON.categoryText;
        }

        allQuesions.push(question)
      }
      return allQuesions;
    }
  }
}
</script>

<style scoped>
@import '../assets/css/common.css';
</style>
