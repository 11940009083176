<style lang="css">
  @import '../assets/css/common.css';
</style>
<template>
  <div class="flex md:flex-row justify-between md:items-end flex-col items-start gap-5 md:gap-0">
    <div>
      <h1 class="text-4xl font-bold tracking-tight text-left text-gray-800">Résultats du questionnaire</h1>
      <p class="mt-4 text-xl text-left text-gray-500">Par Prolibre - Informatique, support, installation, sécurité et développement.</p>
    </div>
    <button @click="showPopup = true" class="h-10 inline-flex items-center rounded-md border border-transparent bg-white border-gray-200 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50">Voir les réponses</button>
  </div>

  <div class="grid">
    <div>
      <div class="separator"></div>
      <div class="flex gap-4 flex-col">
        <div v-for="(item, index) of responses" class="mb-10 text-left text-gray-700 text-l">
          <div v-if="item">
            <div class="flex flex-row items-center gap-4 mb-5">
              <img v-if="index == 0" src="../assets/icones/sheild.svg" class="h-8 mb-2">
              <img v-if="index == 1" src="../assets/icones/data.svg" class="h-8 mb-2">
              <img v-if="index == 2" src="../assets/icones/check.svg" class="h-8 mb-2">
              <p class="text-lg font-medium text-gray-900">{{ categories[index] }}</p>
            </div>

            <div class="flex flex-row gap-2 relative">
              <div class="h-full w-4 bg-red-100 absolute rounded-md" :class="{ 'color-red': item[0] == 'R', 'color-orange': item[0] == 'O', 'color-green': item[0] == 'V' }"></div>
              <div class="flex gap-4 flex-col ml-9">
                <span v-for="x of item[1]">
                  <div v-html="markdownToHtml(x)"></div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="separator2"></div>
    </div>
    <div>
      <p class="mt-4 mb-8 text-xl font-semibold text-gray-700">Voici d'autres conseils :</p>
      <div v-for="other of otherReponses" class="flex flex-row gap-2 relative mb-4">
        <div class="h-full w-4 bg-red-100 absolute rounded-md" :class="{ 'color-red': other[0] == 'R', 'color-orange': other[0] == 'O', 'color-green': other[0] == 'V' }"></div>
        <div class="flex gap-4 flex-col ml-9">
          <div v-html="markdownToHtml(other[1])"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="separator"></div>

  <p class="mt-4 text-xl font-semibold text-gray-700">Intéressé par nos services ?</p>
  <p class="mt-4 mb-4 text-left text-gray-700 text-l">ProLibre propose ses services depuis 2000 pour gérer votre informatique, votre réseau et la sécurité de vos données.</p>

  <div class="flex md:gap-5 md:flex-row flex-col gap-2">
    <div class="p-3 mt-2 bg-white border border-gray-300 rounded-md md:w-fit flex gap-5 justify-between md:justify-start">
      <label class="font-medium text-gray-900">Email</label>
      <a href="mailto:security@prolibre.com" class="text-blue-600 underline">security@prolibre.com</a>
    </div>

    <div class="p-3 mt-2 bg-white border border-gray-300 rounded-md md:w-fit flex gap-5 justify-between md:justify-start">
      <label class="font-medium text-gray-900">Téléphone</label>
      <a href="tel:+41223015383" class="text-blue-600 underline">+41 22 301 53 83</a>
    </div>

    <div class="p-3 mt-2 bg-white border border-gray-300 rounded-md md:w-fit flex gap-5 justify-between md:justify-start">
      <label class="font-medium text-gray-900">Adresse</label>
      <p>Rue des Moraines 18, 1227 Carouge</p>
    </div>
  </div>

  <div class="mb-14"></div>

  <!-------------- MODAL --------------->
    <TransitionRoot as="template" :show="showPopup">
    <Dialog as="div" class="relative z-10" @close="showPopup = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="absolute top-0 right-0 pt-4 pr-4 sm:block">
                <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rouge focus:ring-offset-2" @click="showPopup = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div class="mt-3 sm:mt-0 text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 mb-6">Questions / réponses</DialogTitle>
                  <div class="mt-2">
                    <div v-for="qr in questionsAndAnswers" class="p-3 mt-2 bg-white border border-gray-300 rounded-md">
                      <label class="font-medium text-gray-900" v-html="markdownToHtml(qr[0])"></label>
                      <p class="text-gray-500" v-html="markdownToHtml(qr[1])"></p>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
  import { marked } from 'marked';
  import questionsJSON from '../assets/questions/questions.json'
  
  const baseUrl = process.env.VUE_APP_API_BASE_URL;


  export default {
    name: 'answer',
    methods: {
     async getData() {
      const cleanResponses = []
      let cat = '';
      let cat2 = '';
      let cat3 = '';
      let color = '';
      this.questionsAndAnswers = this.getQuestionsAndAnswers();
      const categories = localStorage.getItem('responseRaw').split(']')

      for(let category of categories) {
        // DO NOT TOUCH. Big thing that formats the responses
        if(category.split('[')[0].includes('other')) {
          let catoth = category.split('[')[1]
          catoth = catoth.slice(1)
          catoth = catoth.slice(0, -1)
          catoth = catoth.split("'").join('"');
          catoth = catoth.split('", "')
          catoth = catoth.map(x => x.split('"').join("'"));

          let index = catoth.indexOf('');
          if (index !== -1) {
            catoth.splice(index, 1);
          }

          for (let i = 0; i < catoth.length; i++) {
            let itemColor = catoth[i].slice(1, 2)
            let text = catoth[i].slice(3)
            catoth[i] = [itemColor, text]
          }

          this.otherReponses = catoth;
        } else {
          cat = category.split('[')[1]
          if(cat) {
            cat = cat.slice(1)
            cat = cat.slice(0, -1)
            cat2 = cat.split('\\n')
            cat3 = cat2[0].slice(3)
            color = cat2[0].slice(1, 2)
            cat2[0] = cat2[0].slice(3)
            cleanResponses.push([color, cat2]); 
          }
        }
      }

      this.responses = cleanResponses;

      // Send email
      const questionAndAnswers = await this.getQuestionsAndAnswers();
      const sendEmail = localStorage.getItem('sendEmail')
      const email = localStorage.getItem('email')
      let cleanData = [this.responses, this.otherReponses, email, questionAndAnswers]
      cleanData = JSON.stringify(cleanData)

      if(sendEmail == 'true') {
          fetch(`${baseUrl}/sondage/get-email`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: cleanData
          }).then(function(data) {    
            localStorage.setItem('sendEmail', "false")
            localStorage.removeItem('email')
          });
      }
     },
     markdownToHtml(value) {
      return marked(value)
     },
     getQuestionsAndAnswers() {
      const dictQuestionsAndAnswers = []
      let i = 0;
      let allReponses = []

      let reponsesArray = JSON.parse(localStorage.getItem('reponsesSlash'))
      reponsesArray.pop()

      reponsesArray.forEach(element => {
        const reps = element.split('/');

        reps.forEach(element2 => {
          if(element2 != "") allReponses.push(element2)     
        });
      });

      questionsJSON.forEach(question => {
        if(question.category == 'category1' || question.category == 'category2' || question.category == 'category3') {
          let answerNumber = allReponses[i][1];
          let answer = "";

          question.options.forEach(element => {
            if(element.value == answerNumber) answer = element.label;
          });

          dictQuestionsAndAnswers.push([question.title.replace('&nbsp', ' '), answer])
          i += 1;
        }
      });

      return dictQuestionsAndAnswers;
     }
    },
    created(){
      this.getData();
    },
    data() {
      return {
        cat: [],
        cat2: [],
        responses: [],
        otherReponses: [],
        questionsAndAnswers: [],
        showPopup: false,
        categories: ['Sensibilité & Hébergement', 'Perte de fichiers & Sauvegardes', 'Poste de travail & Sécurité']
      }
    }
  }
</script>

<script setup>
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
</script>
