<template>
  <div class="relative overflow-scroll bg-gray-50">
    <div class="relative pt-6 pb-16 sm:pb-24" style="height:100vh">
      <Popover>
        <nav class="relative mx-auto flex max-w-7xl items-center justify-between px-4 xl:px-0"  aria-label="Global">
          <div class="flex flex-1 items-center">
            <div class="flex w-full items-center justify-between md:w-auto">
              <RouterLink to="/"><img class="h-5 w-auto" src="../src/assets/images/prolibre-logo.png" alt="prolibre-logo"/></RouterLink>
              <div class="-mr-2 flex items-center md:hidden">
                <PopoverButton class="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-200 p-2 text-gray-400 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-red-900">
                  <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="hidden space-x-10 md:ml-10 md:flex">
              <a v-for="item in navigation" :key="item.name" :href="item.href" target="_blank" class="font-medium text-gray-900 hover:text-gray-700">{{ item.name }}</a>
            </div>
          </div>
          <div class="hidden md:flex">
            <a href="mailto:security@prolibre.com" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white hover:bg-gray-700">Nous contacter</a>
          </div>
        </nav>

        <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden">
            <div class="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div class="flex items-center justify-between px-5 pt-4">
                <div>
                  <RouterLink to="/"><img class="h-5 w-auto" src="../src/assets/images/prolibre-logo.png" alt="prolibre-logo"/></RouterLink>
                </div>
                <div class="-mr-2">
                  <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-900">
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="space-y-1 px-2 pt-2 pb-3">
                <a v-for="item in navigation" :key="item.name" :href="item.href" target="_blank" class="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-500">{{ item.name }}</a>
              </div>
              <a href="mailto:security@prolibre.com" class="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-red-900 hover:bg-gray-100">Nous contacter</a>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>

      <main class="mt-16 sm:mt-24 px-4 xl:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="flex flex-col">
            <RouterView></RouterView>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

  const navigation = [
    { name: 'ProLibre ?', href: 'https://www.prolibre.com/' },
    { name: 'Nos services', href: 'https://www.prolibre.com/produits/' },
    { name: 'Philosophie', href: 'https://www.prolibre.com/philosophie/' },
  ]
</script>

<script>
  import formtest from './views/formtest.vue'

  export default {
    name:'App',
    components: {
      formtest
    },
  }
</script>
