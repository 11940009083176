import { createRouter, createWebHistory } from "vue-router";
import formtest from '../views/formtest.vue'
import answer from '../views/answer.vue'
import home from '../views/home.vue'

const routes = [
  { path: "/", component: home },
  { path: "/quizz", component: formtest },
  { path: "/answer", component: answer },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;