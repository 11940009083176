<template>
  <div class="px-4 sm:px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
    <div>
      <h1 class="mt-0 text-5xl font-bold tracking-tight text-center text-gray-800 sm:mt-8">Évaluez votre sécurité informatique </h1>
      <p class="mt-3 text-md sm:text-base text-center text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">en répondant à quelques questions, cela ne devrait pas vous prendre plus de 5 minutes.</p>
    </div>
  </div>
  <div v-if="formDone" class="flex gap-5 m-auto mt-5 items-center justify-center">
    <router-link to="/quizz">
      <button type="button" class="inline-flex items-center rounded-md border border-transparent bg-red-800 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-900 focus:outline-none">Refaire le test</button>
    </router-link> 
    <RouterLink to="/answer">
      <button type="button" class="inline-flex items-center rounded-md border bg-white px-6 py-3 text-base font-medium text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none">Retour aux réponses ></button>
    </RouterLink>
  </div>

  <div v-if="!formDone" class="flex gap-5 m-auto mt-5 items-center justify-center">
    <router-link to="/quizz">
      <button type="button" class="inline-flex items-center rounded-md border border-transparent bg-red-800 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-900 focus:outline-none">Faire le test</button>
    </router-link> 
  </div>

  <dl class="grid grid-cols-1 gap-5 mt-20 mb-10 lg:grid-cols-3">
    <div v-for="(item, index) in stats" :key="item.id" class="relative overflow-hidden bg-white rounded-lg p-6 flex align-center gap-5 justify-center flex-col border border-gray-300">
      <img v-if="index == 0" src="../assets/icones/lightbulb.svg" class="w-10 h-10" aria-hidden="true" />
      <img v-if="index == 1" src="../assets/icones/check.svg" class="w-10 h-10" aria-hidden="true" />
      <img v-if="index == 2" src="../assets/icones/beaker.svg" class="w-10 h-10" aria-hidden="true" />
      <p class="text-md md:text-xl font-medium text-gray-800 flex h-fit">{{ item.title }}</p>
    </div>
  </dl>

  <div class="separator"></div>
  <div class="flex justify-between w-full">
    <p>Copyright ProLibre - 2024</p>
    <p>V1.0</p>
  </div>

</template>

<script setup>
  const stats = [
    { id: 1, title: 'Conseils personnalisés'},
    { id: 2, title: 'Gratuit et sans engagement' },
    { id: 3, title: 'Analyse professionnelle' },
  ]
</script>

<script>
  export default {
    name:'home',
    created(){
      let answers = localStorage.getItem('responseRaw')
      if(answers) {
        this.formDone = true;
      }
    },
    data() {
      return {
        formDone: false
      }
    }
  }
</script>
