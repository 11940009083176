import FlowForm from './components/FlowForm.vue'
import Question from './components/Question.vue'
import QuestionModel, { QuestionType, ChoiceOption, LinkOption, MaskPresets, MatrixRow, MatrixColumn } from './models/QuestionModel'
import LanguageModel from './models/LanguageModel'
import formtest from '../src/views/formtest'
import { createApp } from 'vue'
import App from './app.vue'
import router from "./router/index.js";

export {
  formtest,
  FlowForm,
  Question,
  QuestionModel,
  QuestionType,
  ChoiceOption,
  LinkOption,
  LanguageModel,
  MaskPresets,
  MatrixRow,
  MatrixColumn
}

const app = createApp(App);
app.use(router);
app.mount('#app')