export default class LanguageModel {
  constructor(options) {
    this.enterKey = 'Enter'
    this.shiftKey = 'Shift'
    this.ok = 'OK'
    this.continue = 'Continuer'
    this.skip = 'Passer'
    this.pressEnter = 'ou :enterKey'
    this.multipleChoiceHelpText = 'Chossissez une ou plusieurs réponses'
    this.multipleChoiceHelpTextSingle = 'Chossissez une seule réponse'
    this.otherPrompt = 'Autre'
    this.placeholder = 'E-Mail'
    this.submitText = 'Envoyer'
    this.longTextHelpText = ':shiftKey + :enterKey to make a line break.'
    this.prev = 'Précedent'
    this.next = 'Suivant'
    this.percentCompleted = ':percent% complété'
    this.invalidPrompt = 'Merci de bien remplir le champ'
    this.thankYouText = 'Merci!'
    this.successText = 'Votre soumission a été envoyée.'
    this.ariaOk = 'Appuie pour continuer'
    this.ariaRequired = 'Cette étape est obligatoire'
    this.ariaPrev = 'Étape précedente'
    this.ariaNext = 'Étape suivante'
    this.ariaSubmitText = 'Appuie pour envoyer'
    this.ariaMultipleChoice = 'ou appuie sur :letter pour selectionner'
    this.ariaTypeAnswer = 'Écrivez votre réponse ici'
    this.errorAllowedFileTypes = 'Type de fichier invalide. Les types acceptés sont : :fileTypes.'
    this.errorMaxFileSize = 'Fichier(s) trop large. Maximum : :size.'
    this.errorMinFiles = 'Pas assez de fichiers ajoutés. Minimum autorisé : :min.'
    this.errorMaxFiles = 'Trop de fichiers ajoutés. Maximium autorisé : :max.'

    Object.assign(this, options || {})
  }

  formatString(string, replacements) {
    return string.replace(/:(\w+)/g, (match, word) => {
      if (this[word]) {
        return '<span class="f-string-em">' + this[word] + '</span>'
      } else if (replacements && replacements[word]) {
        return replacements[word]
      }
      
      return match
    })
  }

  formatFileSize(bytes) {
    const
      units = ['B', 'kB', 'MB', 'GB', 'TB'],
      i = bytes > 0 ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0
      
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + units[i];
  }
}


