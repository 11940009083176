<template>
<div class="relative">
  <select ref="input" class="z-10 cursor-pointer" v-bind:value="dataValue" v-on:change="onChange" v-on:keydown="onKeyDownListener" v-on:keyup="onKeyUpListener" v-bind:required="question.required">
    <option value="" disabled selected>Selectionnez un secteur</option>
    <option v-for="(option, index) in question.options" v-bind:disabled="option.disabled" v-bind:value="option.choiceValue()" v-bind:key="'o' + index">
      {{ option.choiceLabel() }}
    </option>
  </select>
  <img src="../../assets/icones/down.svg" class="w-6 absolute top-3 right-4 z-0 select-icon" aria-hidden="true" />
</div>
</template>

<script>
  import BaseType from './BaseType.vue'
  import { QuestionType } from '../../models/QuestionModel'

  export default {
    extends: BaseType,

    name: QuestionType.Dropdown,
    computed: {
      answerLabel() {
        for (let i = 0; i < this.question.options.length; i++) {
          let option = this.question.options[i]

          if (option.choiceValue() === this.dataValue) {
            return option.choiceLabel()
          }
        }

        return this.question.placeholder
      }
    },
    methods: {
      onKeyDownListener($event) {
        if ($event.key === 'ArrowDown' || $event.key === 'ArrowUp') {
          this.setAnswer(this.dataValue)
        } else if ($event.key === 'Enter' && this.hasValue) {
          this.focused = false
          this.blur()
        }
      },
      
      onKeyUpListener($event) {
        if ($event.key === 'Enter' && this.isValid() && !this.disabled) {
          $event.stopPropagation()
          this._onEnter()
          this.$emit('next')
        }
      }
    }
  }
</script>
